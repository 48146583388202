const axios = require("axios").default

jQuery(document).ready(function ($) {
	//adv search
	$(".adv-search").click(function () {
		$(".adv-search, .adv-content").toggleClass("active")
	})

	//property search
	let data = {
		type: "",
		suburb: "",
		beds: "",
		bathrooms: "",
		cars: "",
		minPrice: "",
		maxPrice: "",
	}
	let page = 1
	let offset = 0
	// const perPage = parseInt($(".property-search-wrapper").attr("data-per-page"))
	const perPage = 99
	const status = $(".property-search").hasClass("sold") ? "sold" : "current"
	const authority = $(".property-search").hasClass("auction") ? "auction" : ""

	// console.log(status)

	const propertySearch = async (data, status, callback) => {
		const propertyURL = `${window.location.origin}/wp-json/wp/v2/property?type=${data.type}&suburb=${data.suburb}&beds=${data.beds}&bathrooms=${data.bathrooms}&cars=${data.cars}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}&_embed&per_page=${perPage}&offset=${offset}&page=${page}&stat=${status}&authority=${authority}`
		const propertyResult = await axios.get(propertyURL)

		const landURL = `${window.location.origin}/wp-json/wp/v2/land?_embed&per_page=${perPage}&offset=${offset}&page=${page}&stat=${status}&authority=${authority}`
		const landResult = await axios.get(landURL)

		const resData = [...propertyResult.data, ...landResult.data]
		// console.log("resData", resData)
		const totalPages = propertyResult.headers["x-wp-totalpages"]
		let counter = 1

		resData.forEach((el, index) => {
			const f_media = el._embedded["wp:featuredmedia"]
			const f_media_url = f_media[0].media_details.sizes.medium_large
				? f_media[0].media_details.sizes.medium_large.source_url
				: f_media[0].media_details.sizes.full.source_url

			const date = new Date(el.meta.property_sold_date * 1000)
			const html = `
          <div class="col-md ${
						(counter === 1 && resData.length >= 3) ||
						(counter === 6 && resData.length >= 3)
							? "larger"
							: ""
					}">
            <a href="${el.link}">
              <figure style="background-image: url(${f_media_url});">
                ${
									el.meta.property_status == "sold"
										? '<div class="notice">' +
										  el.meta.property_status +
										  "</div>"
										: el.meta.property_under_offer[1] == "yes"
										? '<div class="notice">Under Offer</div>'
										: el.meta.property_authority == "auction"
										? '<div class="notice">' +
										  el.meta.property_authority +
										  "</div>"
										: ""
								}
                <div class="figcaption">
                  <h6>${el.meta.property_address_suburb}</h6>
                  <h3>${
										el.meta?.property_address_sub_number[0]
											? el.meta?.property_address_sub_number + ", "
											: ""
									}${el.meta.property_address_street_number} ${
				el.meta.property_address_street
			}</h3>
                  <div class="d-flex justify-content-center">
                    <span>${el.meta.property_bedrooms} BED</span>
                    <span>${el.meta.property_bathrooms} BATH</span>
                    <span>${
											parseInt(el.meta.property_garage) +
											parseInt(el.meta.property_carport) +
											parseInt(el.meta.property_open_spaces)
										} CAR</span>
                  </div>
                </div>
              </figure>
            </a>
          </div>
        `

			$(".properties .row").append(html)

			counter === 6 ? (counter = 1) : counter++
		})

		page < totalPages
			? $(".load-more").addClass("active")
			: $(".load-more").removeClass("active")

		callback()
	}

	//initial load
	propertySearch(data, status, () => {})

	$(".property-search").submit(function (e) {
		e.preventDefault()

		page = 1
		const searchBtn = $(".property-search-btn")

		searchBtn.text("Searching...")

		const el = e.target.elements
		data = {
			type: el.type.value,
			suburb: el.suburb.value,
			beds: el.beds.value,
			bathrooms: el.bathrooms.value,
			cars: el.cars.value,
			minPrice: el.minPrice.value,
			maxPrice: el.maxPrice.value,
		}

		$(".properties .row").empty()

		propertySearch(data, status, () => {
			searchBtn.text("SEARCH")
		})
	})

	$(".load-more").click(function (e) {
		page = page + 1
		offset = offset + perPage

		$(".load-more").text("LOADING MORE PROPERTIES...")

		propertySearch(data, status, () => {
			$(".load-more").text("LOAD MORE PROPERTIES")
		})
	})
})
